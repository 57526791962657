export const isEmpty = (fields, setFunctions, errorMessage) => {
  let valid = false

  fields.forEach((item, index) => {
      if(item.value === ''){
          valid = true
          setFunctions[index]({ ...item, error: errorMessage })
      }
  })

  return valid
}

export const isEmail = (field, setFunction, errorMessage) => {
  if (field.value && !field.value.match(regex.email)) {
    setFunction({...field, error: errorMessage})
    return false
  }
  return true
}

export const isPhone = (field, setFunction, errorMessage) => {
  if (field.value && field.originalValue.length < 10){
    setFunction({...field, error: errorMessage})
    return false
  }
  return true
}

const regex = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};