import styled from 'styled-components'

import { colors } from 'src/commons'

export const ContentWrapper = styled.div`
  width: 100%;
  height: 381px;
`

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: ${props => props.success ? 'none' : 'block'};
`
export const Title = styled.h1`
  color: ${props => props.backgroundLight ? colors.black : colors.white};
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
`

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  trasition: display 0.3s;
  display: ${props => props.loading === 'true' ? 'none' : 'block'}
`

export const LoaderWrapper = styled.div`
  margin-top: 20px;
  trasition: display 0.3s;
  display: ${props => props.loading === 'true' ? 'block' : 'none'}
`