import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import * as S from './styled'

const SucessFeedback = ({success}) => {
  const { 
    strapiFormularioDeContato: { 
      mensagem_de_sucesso
    } 
  } = useStaticQuery(
      graphql`
        query {
          strapiFormularioDeContato {
            mensagem_de_sucesso
          }
        }
    `
  )
  
  return (
    <S.ContentWrapper
      success={success}
    >
      <S.SuccessCheckAnimation>
        <div className="check-icon">
          <span className="icon-line line-tip"></span>
          <span className="icon-line line-long"></span>
          <div className="icon-circle"></div>
          <div className="icon-fix"></div>
        </div>
      </S.SuccessCheckAnimation>
      <S.Message>
        {mensagem_de_sucesso}
      </S.Message>
    </S.ContentWrapper>

  )
}

export default SucessFeedback