import React, {useState} from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import TextInput from 'src/components/TextInput'
import Button from 'src/components/Button'
import Loader from 'src/components/Loader'
import SuccessFeedback from './SucessFeedback'

import {isEmpty, isEmail, isPhone} from 'src/helpers/validateFields'
import {submitMessage} from 'src/services/contactUs'

import {handleChangeText, handleChangeTextMasked} from 'src/helpers/inputChanges'
import {patterns} from 'src/commons'

import * as S from './styled'

const ContactUsForm = ({backgroundLight}) => {
  const [name, setName] = useState({value: ''})
  const [email, setEmail] = useState({value: ''})
  const [phone, setPhone] = useState({value: ''})
  const [message, setMessage] = useState({value: ''})

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const { 
    strapiFormularioDeContato: { 
      titulo, 
      placeholder_campo_nome, 
      placeholder_campo_email, 
      placeholder_campo_telefone, 
      placeholder_campo_mensagem, 
      texto_do_botao,
      erro_campo_obrigatorio,
      erro_telefone_invalido,
      erro_email_invalido
    } 
  } = useStaticQuery(
      graphql`
        query {
          strapiFormularioDeContato {
            titulo
            placeholder_campo_nome
            placeholder_campo_email
            placeholder_campo_telefone
            placeholder_campo_mensagem
            texto_do_botao
            erro_campo_obrigatorio
            erro_telefone_invalido
            erro_email_invalido
          }
        }
    `
  )

  const isValid = () => {
    const valid = !isEmpty(
      [name, phone, email, message],
      [setName, setPhone, setEmail, setMessage],
      erro_campo_obrigatorio
    )
    const validPhone = isPhone(phone, setPhone, erro_telefone_invalido)
    const validEmail = isEmail(email, setEmail, erro_email_invalido)

    return valid && validPhone && validEmail
  }

  const handleSubmiting = async () => {
    if (!isValid()) return

    setLoading(true)

    await submitMessage({name, phone, email, message})
    setSuccess(true)

    setLoading(false)
  }

  return (
    <S.ContentWrapper>
      <S.FormWrapper
        success={success}
      >
        <S.Title
          backgroundLight={backgroundLight}
        >
          {titulo}
        </S.Title>
        <TextInput 
          placeholder={placeholder_campo_nome}
          disabled={loading}
          {...name}
          onChange={event => handleChangeText(event, setName)}
        />
        <TextInput 
          placeholder={placeholder_campo_email}
          disabled={loading}
          {...email}
          onChange={event => handleChangeText(event, setEmail)}
        />
        <TextInput 
          placeholder={placeholder_campo_telefone}
          disabled={loading}
          {...phone}
          onChange={event => handleChangeTextMasked(event, setPhone, patterns.phone)}
        />
        <TextInput 
          placeholder={placeholder_campo_mensagem}
          disabled={loading}
          type='area'
          {...message}
          onChange={event => handleChangeText(event, setMessage)}
        />

        <S.ButtonWrapper
          loading={loading.toString()}
        >
          <Button 
            type={backgroundLight ? 'purple' : 'white'}
            text={texto_do_botao}
            onClick={handleSubmiting}
            loading={loading}
          />
        </S.ButtonWrapper>
        <S.LoaderWrapper
          loading={loading.toString()}
        >
          <Loader />
        </S.LoaderWrapper>
      </S.FormWrapper>

      <SuccessFeedback 
        success={success}
      />
    </S.ContentWrapper>
  )
}

export default ContactUsForm