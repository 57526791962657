import { mask, unMask } from "remask";

export const handleChangeTextMasked = (event, set, pattern) => {
  const { value } = event.target;

  const originalValue = unMask(value);
  const maskedValue = mask(originalValue, pattern);

  set({ value: maskedValue, error: "", originalValue });
};

export const handleChangeText = (event, set) => {
  const { value } = event.target;

  set({ value, error: "" });
};