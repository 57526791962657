import React from 'react'

import * as S from './styled'

const TextInput = ({type, error, ...props}) => {
  const Input = type === 'area' ? S.InputArea : S.Input
  return (
    <S.InputWrapper>
      <Input {...props} />
      <S.ErrorFeedback
        type={type}
      >
        {error}
      </S.ErrorFeedback>
    </S.InputWrapper>
  )
}

export default TextInput