import styled from 'styled-components'
import media from 'styled-media-query'

import { colors } from 'src/commons'

export const InputWrapper = styled.div`
  margin: 5px 0;
`

const commonsProps = `
  color: ${colors.black};
  background: ${colors.white};
  font-size: 14px;
  font-weight: 400;
  padding: 7px;
  border: 1px solid ${colors.purple};
  border-radius: 6px;
  width: 100%;
  max-width: 100%;
  transition: background-color 0.3s;

  :focus {
    outline: none;
  }

  :disabled {
    color: ${colors.black};
    background-color: ${colors.gray};
  }
`

export const Input = styled.input`
  ${commonsProps}
`

export const InputArea = styled.textarea`
  ${commonsProps}
  min-height: 60px;
  max-height: 100px;

  p {
    margin-top: -5px;
  }
`

export const ErrorFeedback = styled.p`
  color: ${colors.orange};
  font-size: 12px;
  height: 15px;
  padding-left: 5px;
  margin-top: ${props => props.type === 'area' ? '-7px': '0px'};

  ${media.lessThan("small")`
    font-size: 9px;
    height: 12px;
    margin-top: ${props => props.type === 'area' ? '-7px': '0px'};
  `}
`
