import axios from 'axios'

export const submitMessage = async (params) => {
  try{
    await axios.post('https://getform.io/f/02cc9b28-bae7-428b-af91-2c171d42389b', buildSubmitMessageBody(params))
    return true
  } catch {
    return false
  }
}

const buildSubmitMessageBody = ({
  name,
  email,
  phone,
  message
}) => {
  return {
    nome: name.value,
    email: email.value,
    telefone: phone.value,
    menssagem: message.value,
  }
}